import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from 'src/components/Typography'

const Styled = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 100%;
  width: 100%;
`

const Wrap = styled.div`
  min-width: 0;
  /* max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + 'px' : 'none')}; */
`

const Title = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  > * {
    display: inline;
  }
`

const Label = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  height: 22px;
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  margin-left: 7px;
  border-radius: 2px;
  color: white;
  width: 56px;
`

const ProjectTitle = props => {
  const { children, ongoing, ...passProps } = props

  return (
    <Styled {...passProps}>
      <Wrap>
        <Title>{children}</Title>
      </Wrap>
      {ongoing && (
        <Label>
          <Typography variant="projectTitleLabel">Laufend</Typography>
        </Label>
      )}
    </Styled>
  )
}

ProjectTitle.propTypes = {
  ongoing: PropTypes.bool,
  title: PropTypes.string,
}

ProjectTitle.defaultProps = {
  ongoing: false,
  title: null,
}

export default ProjectTitle
