import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SlickSlider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SliderArrow from '../SliderArrow/SliderArrow'

const Styled = styled(SlickSlider)`
  &.slick-slider {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.spacing(180 / 8, 'px')};
  }

  .slick-list {
    padding-bottom: 1px;
  }

  .slick-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: ${({ theme }) => theme.spacing(80 / -8, 'px')};

    li {
      display: inline-flex;
      align-items: center;
      margin: 0 5px;
      width: auto;
      height: auto;
      padding: 0;
    }
  }
`

const Dots = styled.div`
  margin: 0 auto;
`
const Dot = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;

  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: transparent;
    border: 1px solid currentColor;
    border-radius: 10px;
    margin: 0 auto;

    .slick-active & {
      background: currentColor;
    }
  }
`

const defaultSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  nextArrow: <SliderArrow direction="right" />,
  prevArrow: <SliderArrow direction="left" />,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1276,
      settings: {
        arrows: false,
      },
    },
  ],
  customPaging: i => <Dot />,
  appendDots: dots => <Dots>{dots}</Dots>,
}

const Slider = props => {
  const {
    className,
    children,
    initial,
    slidesToShow,
    slidesToScroll,
    arrows,
    onChange,
  } = props

  const [key, setKey] = useState(1)
  const slider = useRef(null)

  React.useLayoutEffect(() => {
    setKey(2)
  }, [])

  const settings = {
    ...defaultSettings,
    slidesToShow,
    slidesToScroll,
    arrows,
    infinite: children.length > slidesToShow,
    initialSlide: initial,
    afterChange: current => onChange && onChange(current),
  }

  return (
    <div key={key}>
      <Styled {...{ className, ...settings }} ref={slider}>
        {children}
      </Styled>
    </div>
  )
}

Slider.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  onChange: PropTypes.func,
  arrows: PropTypes.bool,
}

Slider.defaultProps = {
  initial: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
}

export default Slider
