import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import BackgroundImage from '../BackgroundImage'
import { Box } from '@material-ui/core'
import { Link } from 'gatsby'

const Styled = styled(Box)`
  justify-content: ${({ justifycontent }) => justifycontent};
  text-decoration: none;
  align-items: ${({ alignitems }) => alignitems};
  min-height: 340px;
  position: relative;
  padding: 20px;
  display: flex;
  color: white;

  ${({ orientation }) =>
    orientation === 'portrait' &&
    css`
      min-height: 480px;
    `}
`

const StyledBackground = styled.div`
  z-index: -1;

  &:after {
    content: '';
    background: linear-gradient(
      170deg,
      rgba(0, 0, 0, 0) 240px,
      rgba(0, 0, 0, 0.63)
    );
    position: absolute;
    display: block;
    bottom: 0;
    height: 100%;
    width: 100%;
    left: 0;
  }
`

const Content = styled(Box)`
  position: relative;
`

const TeaserBox = props => {
  const { imgFluid, to, children, portrait, ...passProps } = props

  return (
    <Styled
      orientation={portrait ? 'portrait' : 'landscape'}
      to={to}
      as={to ? Link : 'div'}
      {...passProps}
    >
      {imgFluid && (
        <StyledBackground>
          <BackgroundImage fluid={imgFluid} />
        </StyledBackground>
      )}
      <Content>{children}</Content>
    </Styled>
  )
}

TeaserBox.propTypes = {
  children: PropTypes.any,
  justifycontent: PropTypes.string,
  alignitems: PropTypes.string,
  imgSources: PropTypes.array,
  imgSrc: PropTypes.string,
  href: PropTypes.string,
  portrait: PropTypes.bool,
}

TeaserBox.defaultProps = {
  justifycontent: 'flex-start',
  alignitems: 'flex-end',
  portrait: false,
}

export default TeaserBox
