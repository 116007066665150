import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import Typography from 'src/components/Typography'
import List from 'src/components/List'
import Tag from 'src/components/Tag'
import _ from 'lodash'
import Container from 'src/components/Container'
import Button from 'src/components/Button'

const Styled = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
`

const Row = styled(Box)`
  margin: ${({ theme }) => theme.spacing(30 / 8, 'px')} 0;
`

const ModalContainer = styled(Container)`
  flex-shrink: 1;
  padding-top: ${({ theme }) => theme.spacing(100 / 8, 'px')};
`

const Scroll = styled.div`
  overflow: auto;
`

const ModalActions = styled(Box)`
  width: 100%;
  border-top: 1px solid currentColor;
  padding: ${({ theme }) => theme.spacing(30 / 8, 'px')} 0;
  background: black;
`

const TagGroup = styled(Box)`
  > * {
    margin-bottom: ${({ theme }) => theme.spacing(15 / 8, 'px')};
  }
`

const filterLabels = {
  focus: 'Schwerpunkt',
  builder: 'Bauherr',
  services: 'Leistungen',
  tags: 'Bautyp',
  year: 'Jahr',
}

const FilterSelect = props => {
  const { options, onChange, value } = props
  const [active, setActive] = useState(value || [])

  useEffect(() => {
    onChange(active)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  useEffect(() => {
    setActive(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleOnClick = option => {
    active.indexOf(option) !== -1
      ? setActive(_.without(active, option))
      : setActive([...active, option])
  }

  return (
    <TagGroup>
      {options.sort().map((option, i) => {
        return (
          <Tag
            key={i}
            active={active.indexOf(option) !== -1}
            onClick={() => handleOnClick(option)}
          >
            {option}
          </Tag>
        )
      })}
    </TagGroup>
  )
}

const ProjectFilter = props => {
  const { passProps, onChange, options, value } = props
  const [filters, setFilters] = useState({
    ...Object.keys(options).reduce((o, key) => ({ ...o, [key]: [] }), {}),
    ...value,
  })

  const onFilterChange = (key, val) => {
    setFilters({ ...filters, [key]: val })
  }

  const onFilterReset = () => {
    setFilters(_.mapValues(filters, () => []))
  }

  const onFilterApply = () => {
    const enabledFilters = _.omitBy(filters, _.isEmpty)
    onChange(Object.keys(enabledFilters).length ? enabledFilters : null)
  }

  return (
    <Styled {...passProps}>
      <Scroll>
        <ModalContainer>
          <List color="white">
            {Object.keys(options).map((key, i) => (
              <Row key={i}>
                <Box>
                  <Typography variant="body" paragraph>
                    {filterLabels[key]}
                  </Typography>
                  <FilterSelect
                    value={filters[key]}
                    onChange={val => onFilterChange(key, val)}
                    options={options[key]}
                  />
                </Box>
              </Row>
            ))}
          </List>
        </ModalContainer>
      </Scroll>
      <ModalActions>
        <Container>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button color="link" onClick={onFilterReset}>
              Alle löschen
            </Button>
            <Button color="white" variant="contained" onClick={onFilterApply}>
              Filter anwenden
            </Button>
          </Box>
        </Container>
      </ModalActions>
    </Styled>
  )
}

ProjectFilter.propTypes = {
  options: PropTypes.object,
}

ProjectFilter.defaultProps = {
  options: {},
}

export default ProjectFilter
