import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../Button'

const StyledButton = styled(Button)`
  padding-right: ${({ theme }) => theme.spacing(3, 'px')};
  padding-left: ${({ theme }) => theme.spacing(3, 'px')};

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(15 / 8, 'px')};
  }
`

const Tag = props => {
  const { active, children, ...passProps } = props
  return (
    <StyledButton variant={active ? 'contained' : 'default'} {...passProps}>
      {children}
    </StyledButton>
  )
}

Tag.propTypes = {
  children: PropTypes.any,
  active: PropTypes.bool,
  color: PropTypes.string,
}

Tag.defaultProps = {
  color: 'white',
}

export default Tag
