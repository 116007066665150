import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { ReactComponent as ArrowIcon } from './arrow.svg'

const Styled = styled.div`
  &.slick-prev,
  &.slick-next {
    width: 20px;
    height: auto;
    color: rgba(0, 0, 0, 0.5);
    &:before {
      display: none;
    }
    &:hover {
      color: black;
    }

    &.slick-prev {
      left: -80px;
    }
    &.slick-next {
      right: -80px;
    }
  }
`

const Icon = styled(ArrowIcon)`
  transform: ${({ direction }) =>
    direction === 'right' ? 'none' : 'rotate(-180deg)'};
`

const SliderArrow = props => {
  const { className, direction, onClick } = props

  return (
    <Styled {...{ className, onClick }}>
      <Icon {...{ direction }} />
    </Styled>
  )
}

SliderArrow.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right']),
}

SliderArrow.defaultProps = {
  //className: 'class'
}

export default SliderArrow
