import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import Typography from 'src/components/Typography'
import Table from 'src/components/Table'
import Button from 'src/components/Button'
import ProjectTitle from 'src/components/ProjectTitle'
import Accordion from 'src/components/Accordion'
import AccordionItem from 'src/components/AccordionItem'
import { graphql } from 'gatsby'
import { Box } from '@material-ui/core'
import Modal from 'src/components/Modal'
import ProjectFilter from 'src/components/ProjectFilter'
import _ from 'lodash'
import ServiceRange from 'src/components/ServiceRange'
import ShowMore from 'src/components/ShowMore'
import Container from 'src/components/Container'
import ProjectLinkButton from 'src/components/ProjectLinkButton'

const Styled = styled.div`
  .table {
    display: none;
  }

  ${up('md')} {
    .table {
      display: table;
    }
    .accordion {
      display: none;
    }
  }
`

const NoResults = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
`

const ProjectList = props => {
  const { projects, passProps, filterOptions, hideColumns } = props
  const [filters, setFilters] = useState(null)
  const [filterCount, setFilterCount] = useState(0)
  const [filteredProjects, setFilteredProjects] = useState(projects)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    if (!filters) {
      setFilterCount(0)
      setFilteredProjects(projects)
      return
    }

    const filterKeys = Object.keys(filters)

    const data = projects.filter(entry => {
      return filterKeys.every(key => {
        if (key === 'focus') {
          return filters[key].indexOf(entry[key].title) !== -1
        }

        if (key === 'tags') {
          return entry[key].find(x => filters[key].indexOf(x.value) !== -1)
        }

        return filters[key].some(elem => [].concat(entry[key]).includes(elem))
      })
    })

    setFilteredProjects(data)
    setFilterCount(_.reduce(filters, (sum, n) => sum + n.length, 0))
  }, [filters, projects])

  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Projekt',
        accessor: 'title',
        maxWidth: '300px',
        Cell: ({
          cell: {
            value,
            row: {
              original: { status },
            },
          },
        }) => {
          return (
            <ProjectTitle title={value} {...{ ongoing: status === 'unlisted' }}>
              {value}
            </ProjectTitle>
          )
        },
      },
      {
        Header: 'Ort',
        accessor: 'location',
        maxWidth: '150px',
        show: !hideColumns.includes('location'),
        Cell: ({ cell: { value: location } }) => (
          <span title={location}>{location}</span>
        ),
      },
      // {
      //   Header: 'Bauherr',
      //   accessor: 'builder',
      //   maxWidth: '150px',
      //   show: !hideColumns.includes('builder'),
      // },
      {
        Header: 'Schwerpunkt',
        accessor: 'focus',
        maxWidth: '150px',
        show: !hideColumns.includes('focus'),
        Cell: ({
          cell: {
            value: { title },
          },
        }) => {
          const text = title === 'Kein Schwerpunkt' ? '' : title
          return <span title={text}>{text}</span>
        },
      },
      {
        Header: 'Bau-Typ',
        accessor: 'tags',
        maxWidth: '200px',
        show: !hideColumns.includes('tags'),
        Cell: ({ cell: { value } }) => {
          const text = _.orderBy(value, 'value')
            .map(n => n.value)
            .join(', ')
          return <span title={text}>{text}</span>
        },
      },
      // {
      //   Header: 'Leistung',
      //   accessor: 'services',
      //   show: !hideColumns.includes('services'),
      //   Cell: ({ cell: { value } }) => {
      //     return <ServiceRange values={value} />
      //   },
      // },
      {
        Header: 'Jahr',
        accessor: 'year',
        maxWidth: '80px',
        show: !hideColumns.includes('year'),
      },
      {
        Header: '',
        id: 'actions',
        // maxWidth: '50px',
        show: !hideColumns.includes('actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <ProjectLinkButton entry={original} />,
      },
    ],
    [hideColumns]
  )

  const toggleFilterModal = () => {
    setModalVisible(!modalVisible)
  }

  const handleOnRequestClose = () => {
    setModalVisible(false)
  }

  const handleOnFilterChange = filters => {
    setModalVisible(false)
    setFilters(filters)
  }

  return (
    <Styled {...passProps}>
      {filterOptions && (
        <Box display="flex" justifyContent="flex-end" mb={40 / 8}>
          <Button
            color="secondary"
            onClick={toggleFilterModal}
            variant={filters ? 'contained' : undefined}
          >
            Projekte filtern {filterCount > 0 && <>({filterCount})</>}
          </Button>
        </Box>
      )}
      {filteredProjects.length > 0 ? (
        <>
          <Table
            className="table"
            {...{
              columns: tableColumns,
              data: filteredProjects,
              initial: filters ? -1 : 10,
              showMore: -1,
            }}
          />
          <Accordion
            className="accordion"
            allowMultipleExpanded
            allowZeroExpanded
          >
            <ShowMore initial={filters ? -1 : 10}>
              {filteredProjects.map((entry, idx) => (
                <AccordionItem
                  title={
                    <ProjectTitle {...{ ongoing: entry.status === 'unlisted' }}>
                      {entry.title}
                    </ProjectTitle>
                  }
                  key={idx}
                  project
                >
                  <Typography variant="body" paragraph>
                    {entry.year} ·{' '}
                    {entry.focus.title !== 'Kein Schwerpunkt'
                      ? `${entry.focus.title} ·`
                      : ''}{' '}
                    {entry.location} · {entry.builder} ·{' '}
                    <ServiceRange values={entry.services} />
                  </Typography>
                  {entry.intro !== '' && (
                    <Typography variant="body" paragraph>
                      {entry.intro}
                    </Typography>
                  )}
                  <ProjectLinkButton {...{ entry }} />
                </AccordionItem>
              ))}
            </ShowMore>
          </Accordion>
        </>
      ) : (
        <Container>
          <NoResults>
            <Typography variant="body" paragraph>
              Es gibt leider kein Projekt, das Ihrer Anfrage entspricht.
            </Typography>
            <Button
              onClick={() => setFilters(null)}
              variant="link"
              color="secondary"
            >
              Filter aufheben
            </Button>
          </NoResults>
        </Container>
      )}

      {filterOptions && (
        <Modal
          {...{
            visible: modalVisible,
            onRequestClose: handleOnRequestClose,
            appEl: '#root',
            color: 'secondary',
          }}
        >
          <ProjectFilter
            value={filters}
            options={filterOptions}
            onChange={handleOnFilterChange}
          />
        </Modal>
      )}
    </Styled>
  )
}

ProjectList.propTypes = {}

ProjectList.defaultProps = {
  hideColumns: ['year'],
}

export default ProjectList

export const query = graphql`
  fragment ProjectListFragment on projects {
    title
    slug
    status
    location
    builder
    createPage
    services
    tags {
      value
      slug
    }
    focus {
      title
      slug
    }
    year: completed_at(formatString: "YYYY")
    cover {
      local {
        childImageSharp {
          fluid(maxWidth: 490, quality: 60) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`
