import React from 'react'
import styled from 'styled-components'
import Button from 'src/components/Button'

const Styled = styled.div``

const ProjectLinkButton = props => {
  const {
    entry: { slug, createPage },
    ...passProps
  } = props

  if (!createPage) return null

  return (
    <Styled {...passProps}>
      <Button to={'/projekte/' + slug} color="secondary">
        Projekt ansehen
      </Button>
    </Styled>
  )
}

ProjectLinkButton.propTypes = {}

ProjectLinkButton.defaultProps = {}

export default ProjectLinkButton
