import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Styled = styled.span``

const toRanges = numbers => {
  const sorted = numbers.sort()
  const first = sorted.shift()
  return sorted
    .reduce(
      (ranges, num) => {
        if (num - ranges[0][1] <= 1) {
          ranges[0][1] = num
        } else {
          ranges.unshift([num, num])
        }
        return ranges
      },
      [[first, first]]
    )
    .map(ranges => {
      return ranges[0] === ranges[1] ? ranges[0].toString() : ranges.join('-')
    })
    .reverse()
}

const ServiceRange = props => {
  const { values, label, ...passProps } = props
  const numeric = values.map(x => Number(x.replace('LP', '')))
  const ranges = toRanges(numeric)

  return (
    <Styled {...passProps}>
      {label} {ranges.join()}
    </Styled>
  )
}

ServiceRange.propTypes = {
  label: PropTypes.string,
}

ServiceRange.defaultProps = {
  label: 'LPH',
}

export default ServiceRange
