import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTable } from 'react-table'
import Typography from '../Typography/Typography'
import ShowMore from '../ShowMore'
import Container from '../Container'
import Button from '../Button'

const Styled = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
`

const StyledTable = styled.table`
  width: 100%;
  table-layout: auto;
`
const TableHeader = styled.thead``

const TableHeaderGroup = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.main};
`

const TableHeaderHeader = styled.th`
  text-align: left;
  padding: 0px 10px 15px 0;

  &:last-child {
    text-align: right;
    padding-right: 0;
  }
`

const TableRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.main};
`

const TableCell = styled.td`
  margin: 0;
  padding: 15px 10px 15px 0;

  &:last-child {
    text-align: right;
    padding-right: 0;
  }
`

const StyledContainer = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing(5, 'px')};
  text-align: center;
`

const Table = props => {
  const {
    columns,
    data,
    initial,
    showMoreLabel,
    showMore,
    ...passProps
  } = props
  const [visible, setVisible] = React.useState(initial)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  const handleOnClick = () => {
    if (showMore < 0) {
      return setVisible(rows.length)
    }

    setVisible(visible + showMore)
  }

  // Render the UI for your table
  return (
    <Styled {...passProps}>
      <StyledTable {...getTableProps()} {...passProps}>
        <TableHeader>
          {headerGroups.map(headerGroup => (
            <TableHeaderGroup {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers
                .filter(column => column.show !== false)
                .map(column => {
                  return (
                    <TableHeaderHeader
                      {...column.getHeaderProps()}
                      {...{
                        style: {
                          maxWidth: column.maxWidth,
                        },
                      }}
                    >
                      <Typography variant="tableHeader">
                        {column.render('Header')}
                      </Typography>
                    </TableHeaderHeader>
                  )
                })}
            </TableHeaderGroup>
          ))}
        </TableHeader>
        <ShowMore
          as="tbody"
          initial={visible}
          noAction
          {...getTableBodyProps()}
        >
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells
                  .filter(c => c.column.show !== false)
                  .map(cell => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        {...{
                          style: {
                            maxWidth: cell.column.maxWidth,
                          },
                        }}
                      >
                        <Typography variant="tableCell" as="div">
                          {cell.render('Cell')}
                        </Typography>
                      </TableCell>
                    )
                  })}
              </TableRow>
            )
          })}
        </ShowMore>
      </StyledTable>
      {initial !== -1 && initial < rows.length && (
        <StyledContainer>
          <Button
            color="link"
            onClick={handleOnClick}
            disabled={visible >= rows.length}
          >
            {showMoreLabel}
          </Button>
        </StyledContainer>
      )}
    </Styled>
  )
}

Table.propTypes = {
  className: PropTypes.string,
  initial: PropTypes.number,
  showMore: PropTypes.number,
  showMoreLabel: PropTypes.string,
}

Table.defaultProps = {
  initial: -1,
  showMore: 3,
  showMoreLabel: 'Mehr anzeigen',
}

export default Table
