import Box from '@material-ui/core/Box'
import { graphql } from 'gatsby'
import React from 'react'
import Markdown from 'src/components/Markdown'
import BackgroundImage from 'src/components/BackgroundImage'
import Button from 'src/components/Button'
import Competence from 'src/components/Competence'
import Container from 'src/components/Container'
import FlexibleLogo from 'src/components/FlexibleLogo'
import GradientOverlay from 'src/components/GradientOverlay'
import Grid from 'src/components/Grid'
import Hero from 'src/components/Hero'
import HeroContent from 'src/components/HeroContent'
import Image from 'src/components/Image'
import ProjectList from 'src/components/ProjectList'
import ProjectTitle from 'src/components/ProjectTitle'
import Section from 'src/components/Section'
import Slider from 'src/components/Slider'
import TeaserBox from 'src/components/TeaserBox'
import Typography from 'src/components/Typography'
import Layout from '../layout'
import styled from 'styled-components'
import _ from 'lodash'
import ProjectLinkButton from 'src/components/ProjectLinkButton'

// Just to change the box model
const StyledProject = styled.div`
  overflow: hidden;
`

export default function Focus(props) {
  const {
    projects: { nodes: projects },
    // stories: { nodes: stories },
    focuses: { nodes: focuses },
  } = props.data

  const {
    title,
    cover,
    intro,
    text,
    images,
    competences,
    contactTitle,
    contactText,
    contact,
    metaTitle,
    metaDescription,
    metaImage,
    featured,
  } = focuses[0]

  const contactProps = {
    contactTitle,
    contactText,
    contact,
  }

  const metaProps = {
    metaTitle,
    metaDescription,
    metaImage,
  }

  return (
    <Layout contact={contactProps} meta={metaProps}>
      {/*
            Hero
      */}
      <Section border={false} flush>
        <Hero>
          {cover && cover.local && (
            <BackgroundImage
              darken={0.2}
              fluid={cover.local.childImageSharp.fluid}
            />
          )}
          <GradientOverlay color="bottomLeft" multiply />
          <FlexibleLogo color="primary" size={80} />
          <HeroContent>
            <Typography variant="heroLabel" paragraph>
              {title}
            </Typography>
            <Typography variant="hero" paragraph>
              {intro}
            </Typography>
            <Button color="white" to="#projects">
              Projekte ansehen
            </Button>
          </HeroContent>
        </Hero>
      </Section>

      {/*
            Intro
       */}
      <Section color="primary" border={false} flush>
        <Container>
          <Box maxWidth={700} mx="auto" mb={60 / 8}>
            <Typography variant="teaser">
              <Markdown source={text} />
            </Typography>
          </Box>
        </Container>
      </Section>

      {/*
          Images
      */}
      {images && images.length > 0 && (
        <Section border={false} flush>
          <Container>
            <Grid variant="2x">
              {images.map((entry, key) => (
                <Box key={key}>
                  <Image fluid={entry.local.childImageSharp.fluid} />
                </Box>
              ))}
            </Grid>
          </Container>
        </Section>
      )}

      {/*
          Competences
      */}
      {competences && competences.length > 0 && (
        <Section color="transparent">
          <Container>
            <Typography variant="h1" paragraph>
              Kompetenzen
            </Typography>
            <Grid variant="2x">
              {competences.map((entry, key) => (
                <Competence
                  key={key}
                  index={key + 1}
                  title={entry.title}
                  text={entry.text}
                />
              ))}
            </Grid>
          </Container>
        </Section>
      )}

      {/*
            Projects
      */}
      {featured && featured.length > 0 && (
        <Section id="projects" color="transparent">
          <Container>
            <Typography variant="h1" paragraph>
              Projekte
            </Typography>
            <Grid variant="2x">
              {featured.map((entry, key) => (
                <StyledProject key={key}>
                  {entry.cover && entry.cover.local && (
                    <Image fluid={entry.cover.local.childImageSharp.fluid} />
                  )}
                  <Box mt={20 / 8} mx={20 / 8}>
                    <div>
                      <ProjectTitle
                        {...{ ongoing: entry.status === 'unlisted' }}
                      >
                        <Typography variant="h2">{entry.title}</Typography>
                      </ProjectTitle>

                      {entry.intro !== '' && (
                        <Typography variant="body" paragraph>
                          {entry.intro}
                        </Typography>
                      )}
                      <ProjectLinkButton entry={entry} />
                    </div>
                  </Box>
                </StyledProject>
              ))}
            </Grid>
          </Container>
        </Section>
      )}
      {/*
            Projects Table / Accordion
      */}

      <Section color="secondary" flush border={false}>
        <Container>
          <Typography variant="h3" paragraph>
            Weitere Projekte
          </Typography>
          <ProjectList
            projects={projects}
            filterOptions={{
              year: _.uniq(projects.map(({ year: x }) => x)),
              tags: _.uniq(
                _.flatten(
                  projects.map(({ tags: x }) => x.map(tag => tag.value))
                )
              ),
            }}
          />
        </Container>
      </Section>

      {/*
            Stories
      */}
      {/*
      <Section color="secondary">
        <Container>
          <Typography variant="h1" paragraph>
            Stories
          </Typography>
          <Slider slidesToScroll={3} slidesToShow={3}>
            {stories.map((entry, key) => (
              <TeaserBox
                imgFluid={entry.cover.childImageSharp.fluid}
                portrait={key % 3 === 0}
                key={key}
                to={`/stories/${entry.slug}`}
              >
                <Typography variant="h2" paragraph>
                  {entry.title}
                </Typography>
                <Button color="white">Artikel lesen</Button>
              </TeaserBox>
            ))}
          </Slider>
        </Container>
      </Section>
      */}
    </Layout>
  )
}

export const query = graphql`
  query($id: String, $id__normalized: String) {
    focuses: allFocuses(filter: { id: { eq: $id } }) {
      nodes {
        title
        slug
        cover {
          local {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        intro
        text
        images {
          local {
            childImageSharp {
              fluid(maxWidth: 490, maxHeight: 325, quality: 60) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        competences {
          title
          text
        }
        featured {
          title
          slug
          intro
          createPage
          cover {
            local {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 60) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        contactTitle
        contactText
        contact {
          ...ContactFragment
        }
        metaTitle
        metaDescription
        metaImage {
          local {
            childImageSharp {
              fixed(width: 1024, quality: 60) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }

    projects: allProjects(
      filter: { focus: { id__normalized: { eq: $id__normalized } } }
      sort: { order: DESC, fields: completed_at }
    ) {
      nodes {
        ...ProjectListFragment
      }
    }
  }
`

// stories: allStories(limit: 9, filter: { id: { ne: "dummy" } }) {
//   nodes {
//     title
//     slug
//     cover {
//       local {
//         childImageSharp {
//           fluid(maxWidth: 360, quality: 60) {
//             ...GatsbyImageSharpFluid_noBase64
//           }
//         }
//       }
//     }
//   }
// }
